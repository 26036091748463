<template>
  <section class="w-100 TagProducts" style="padding: 15px 10px">
    <div class="row mb-3">
      <div
        class="col-12 d-flex back-button align-items-center"
        @click="$emit('backFromVariant', '')"
        style="cursor: pointer"
      >
        <i class="fa-solid mr-2 fa-chevron-left"></i>
        <div>Back to Tags</div>
      </div>
    </div>
    <div
      class="row questions-not-font flex-column d-flex justify-content-center align-items-center"
      v-if="areNotAvailableProducts"
    >
      <LazyImage
        :src="'/assets/img/noData.svg'"
        alt=""
        style="width: 15rem"
        class="mt-5"
      />
      <div class="row text-center px-2">
        <div class="col-12">
          <h3
            class="pt-5"
            style="
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 21px;

              letter-spacing: 0.04em;

              color: #4d4950;
            "
          >
            No products found for selected tag!
          </h3>
        </div>
      </div>
    </div>

    <b-skeleton type="input" v-else-if="isLoadingProducts"></b-skeleton>
    <div class="" v-else>
      <div
        class="collectionSearchInput col-12 row align-items-center mx-1 pr-3 py-1"
        v-if="!areNotAvailableProducts"
      >
        <div style="cursor: pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M9.5 3C11.2239 3 12.8772 3.68482 14.0962 4.90381C15.3152 6.12279 16 7.77609 16 9.5C16 11.11 15.41 12.59 14.44 13.73L14.71 14H15.5L20.5 19L19 20.5L14 15.5V14.71L13.73 14.44C12.59 15.41 11.11 16 9.5 16C7.77609 16 6.12279 15.3152 4.90381 14.0962C3.68482 12.8772 3 11.2239 3 9.5C3 7.77609 3.68482 6.12279 4.90381 4.90381C6.12279 3.68482 7.77609 3 9.5 3ZM9.5 5C7 5 5 7 5 9.5C5 12 7 14 9.5 14C12 14 14 12 14 9.5C14 7 12 5 9.5 5Z"
              fill="#73738D"
            />
          </svg>
        </div>
        <input
          type="text"
          class="mx-2 flex-grow-1"
          v-model="searchProduct"
          placeholder="Search for Products"
        />
      </div>
    </div>
    <div
      class="w-100 p-3 collectionBox"
      v-if="isLoadingProducts"
      style="border: none"
    >
      <div class="skel">
        <div class="mb-2 py-1" v-for="index in 5" :Key="index">
          <div class="d-flex align-items-center">
            <b-skeleton type="avatar" animation="throb"></b-skeleton>
            <b-skeleton
              type="input"
              class="ml-2 w-86"
              animation="throb"
            ></b-skeleton>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div
      class="w-100 p-3 collectionBox"
      v-if="!areNotAvailableProducts && getProducts.length > 0"
      style="border: none"
    >
      <div
        :class="['mb-2 py-1,collection-row']"
        v-for="collection in getProducts"
        :key="collection.id"
      >
        <div class="d-flex align-items-center collection">
          <div class="d-flex col-8 px-0 align-items-center">
            <div class=""></div>
            <div class="img ml-2">
              <div v-if="checkFileType(collection.image) == 'video'">
                <video
                  ref="videoRef"
                  :src="`${collection.image}`"
                  alt=""
                  class="img-thumbnail mr-2"
                  style="
                    height: 50px;
                    width: 50px;
                    border-radius: 50%;
                    object-fit: cover;
                  "
                  controls
                ></video>
              </div>
              <LazyImage
                :src="collectionImage(collection.image)"
                :key="1"
                alt=""
                class="product-image"
              />
            </div>
            <div class="singleProduct d-flex align-items-center pl-2">
              <p class="title text-left">
                {{ collection.title }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="w-100 p-3 collectionBox"
      v-if="
        !this.isLoadingProducts && TagProducts.length > 0 && getProducts <= 0
      "
      style="border: none"
    >
      <div
        class="d-flex flex-column h-100 justify-content-center align-items-center text-center mb-2 py-1"
      >
        <div
          class="btn"
          v-b-tooltip.hover.top="'Reset Filter'"
          @click="searchProduct = ''"
        >
          <i class="fa-solid fa-arrow-rotate-right"></i>
        </div>
        <h5>No TagProducts were found matching your filter</h5>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    TagProducts: Array,
    selected: Object,
    isLoadingProducts: Boolean,
  },
  computed: {
    areNotAvailableProducts() {
      return this.isLoadingProducts == false && this.TagProducts.length <= 0
        ? true
        : false;
    },
    checkReset() {
      for (let index in this.collectionFilters) {
        if (index != "isFilterVisible") {
          if (this.collectionFilters[index].selectedOPtion != null) {
            return true;
          }
        }
      }
      if (
        this.collectionFilters.price.above != "" ||
        this.collectionFilters.price.start != "" ||
        this.collectionFilters.price.end != ""
      ) {
        return true;
      }
      return false;
    },

    getProducts() {
      return this.TagProducts.filter((item) => {
        return item.title
          .toLowerCase()
          .includes(this.searchProduct.toLowerCase());
      });
    },
  },
  watch: {
    checkReset(newVal) {
      this.showResetBtn = newVal;
    },
  },
  data() {
    return {
      skeletonCount: 5,
      searchProduct: "",
      isSelectAll: true,
      showResetBtn: false,
      collectionFilters: {
        price: {
          isActive: false,
          selectedOption: null,
          above: "",
          start: "",
          end: "",
        },
        tags: {
          isActive: false,
          allTags: [],
          selectedOPtion: null,
        },
        vendors: {
          isActive: false,
          selectedOPtion: null,
          allVendors: [],
        },
        TagProducts: {
          isActive: false,
          allCollections: [],
          selectedOPtion: null,
        },
        isFilterVisible: false,
      },
    };
  },
  methods: {
    checkFileType(file) {
      let fileType;
      let allowedImageExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      let allowedVideoExtensions = /(\.mp4)$/i;

      if (!allowedImageExtensions.exec(file)) {
        if (allowedVideoExtensions.exec(file)) {
          fileType = "video";
        } else {
          fileType = "unknown";
        }
      } else {
        fileType = "image";
      }

      return fileType;
    },
    collectionImage(image) {
      if (image) return image.startsWith("upload") ? "/" + image : image;
      return "https://images.quizell.com/default/default_image.png";
    },
  },
};
</script>
<style>
.collection-image {
  height: 50px;
  width: 50px;
  /* border: 4px solid rgb(255, 255, 255);  */
  border-radius: 50%;
  object-fit: cover;
  background-size: cover;
  padding: 0.25rem;
  background-color: #fff;
}

.collection-icon {
  color: #4d1b7e;
}

.collection-icon:hover {
  color: black;
}

.collectionSearchInput {
  border: 0.8px solid #18191c14;
  box-sizing: border-box;
  border-radius: 6px;
  background: #ffffff;
}

.collectionSearchInput input {
  background: transparent;
  border: none;
  outline: none;
}

.collectionSearchInput input:focus {
  border: none;
  outline: none;
}

.filterBtn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  align-items: center;
  letter-spacing: 0.04em;
  color: #4d1b7e;
  cursor: pointer;
}

.filterBtn:hover {
  color: black;
}

.w-86 {
  width: 86%;
}

.collectionBox {
  height: 300px;
  /* border: 0.5px solid #b3afb6; */
  box-sizing: border-box;
  border-radius: 10px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.collectionBox .collection {
  border-radius: 6px;
}

.collectionBox .collection:hover {
  background: #b3afb614;
}

.variant-btn {
  display: none;
  font-size: 14px;
  border: 1px solid #b3afb6;
  border-radius: 12px;
  outline: none;
}

.selectedProduct .collection:hover .variant-btn {
  border: 1.5px solid #4d1b7e;
  color: #4d1b7e !important;
}

.selectedProduct .collection:hover .variant-btn .variant-text {
  color: #4d1b7e !important;
}

.collection:hover .variant-btn {
  display: block;
}

.singleProduct .title {
  color: #18191c;
  font-weight: 500;
}
.back-button i {
  transition: 0.5s ease all;
}
.back-button:active {
  color: #4d1b7e;
}
.back-button:hover i {
  transform: translateX(-2px);
}
.singleProduct .price {
  color: #4d4950;
  font-weight: 300;
}
</style>
